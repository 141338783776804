export const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
export const integrationBusCmsBaseUrl =
  process.env.NEXT_PUBLIC_INTEGRATION_BUS_CMS_BASE_URL;
export const cmsBaseUrl = process.env.NEXT_PUBLIC_CMS_BASE_URL;
export const contactBaseUrl = process.env.NEXT_PUBLIC_CONTACT_API_URL;

export const PROTOCOL = "//";
export const DOMAINNAME = process.env.NEXT_PUBLIC_HOSTNAME;
export const MAINDOMAINURL = DOMAINNAME;
export const POSTLOGINURL = "alpha.e-procure.net";
export const EXPERT_MAINDOMAINURL = "";
export const EXPERT_SUBDOMAINURL = "experts";
export const BUG_SNAG_KEY = process.env.NEXT_PUBLIC_BUG_SNAG_KEY;

export const AMAZONE_MEDIA_URL = process.env.NEXT_PUBLIC_AMAZONE_MEDIA_URL;
export const AMAZONE_CLOUD_URL = process.env.NEXT_PUBLIC_AMAZONE_CLOUD_URL;
export const imgurl = AMAZONE_CLOUD_URL;
export const defaultproduct = imgurl + "theme/marketplace_no_image.jpg";
export const ThumbImageRatio = "150x150";

export const csvIcon = imgurl + "document_icon/csv.png";
export const docIcon = imgurl + "document_icon/doc.png";
export const document_downloadIcon =
  imgurl + "document_icon/document-download.png";
export const pdfIcon = imgurl + "document_icon/pdf.png";
export const pptIcon = imgurl + "document_icon/ppt.png";
export const xlsIcon = imgurl + "document_icon/xls.png";
export const xmlIcon = imgurl + "document_icon/xml.png";
export const imgIcon = imgurl + "document_icon/imgIcon.png";
export const blockchain_verified =
  imgurl + "theme/icons/blockchain_verified.png";
export const loginBgImage = `${PROTOCOL + DOMAINNAME}/assets/login/loginImage.webp`;

export const IPDATAAPIKEY = process.env.NEXT_PUBLIC_IPDATAAPIKEY;
export const ACCESSTOKEN_PUBLIC_KEY =
  "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCHquGuixYgvNuZ9knHaUaisll8rXAqqpFCJQMplaC3MP1gVEhs5peuoSrB8G6Uv4oLKQlEGxM2TSi4gyuGTuXmY4fM1Ux8RnzSE+tiUAkhjuvuJQlX88J5PC/tYme/V9bfaV7Hw3xyaXsm//4H78BtBu2B9EuxzGiS7W9tzQqRqwIDAQAB";
export const encryptedIIDKey = process.env.NEXT_PUBLIC_ENCRYPTEDIIDKEY;
export const ipUrl = `${process.env.NEXT_PUBLIC_IPDATAURL}?api-key=${IPDATAAPIKEY}`;

export const USER_TYPE_SUPPLIER = 1;
export const USER_TYPE_BUYER = 2;
export const USER_TYPE_SUB_USER = 3;
export const USER_TYPE_EXPERT = 4;
